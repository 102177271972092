/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";
import { useState,useContext } from "react";

// rellax
import Rellax from "rellax";
import MKButton from "components/MKButton";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import routesEN from "routesEN";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Blogs/SingleArticle/sections/Information";
import Steps from "pages/Blogs/SingleArticle/sections/Steps";
import OurEfforts from "pages/Blogs/SingleArticle/sections/OurEfforts";
import Features from "pages/Blogs/SingleArticle/sections/Features";
import Posts from "pages/Blogs/SingleArticle/sections/Posts";
import Support from "pages/Blogs/SingleArticle/sections/Support";
import {getArticles, getPages } from "../../../src/hooks/airtable";
import { Context } from "../../Context";
import ContactUsOne from "pages/presentation/sections/Contact";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import "./style.css";

// Images
import bgImage from "assets/images/bg5.jpg";

function SingleArticle() {
  const headerRef = useRef(null);
  const [contentData, setContentData] = useState([]);
  const {langContext, setLangContext} = useContext(Context);
  const [isFetch, setIsFetch] = useState(false);
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()    

  // Setting up rellax
/*   useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []); */

  useEffect(() => {
    async function fetchData() {
      const data = await getPages();
      setContentData(data);
      console.log(data)
      console.log(contentData.find(item => item.fields.key === "Section 1 " + langContext));
      setIsFetch(true);
    }
    fetchData();

  }, []);

  const countriesFR = [
    "France",
    "Allemagne",
    "Espagne",
    "Pays-Bas",
    "Italie",
    "Autriche",
    "République-tchèque",
    "Grande-Bretagne",
  ];
  const countriesEN = [
    "France",
    "Germany",
    "Spain",
    "Netherlands",
    "Italy",
    "Austria",
    "Czech Republic",
    "United Kingdom",
  ];

  return (
    <>
      <MKBox shadow="sm" py={0.25} sx={{position: "sticky", top: 0, zIndex: 10,backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",}}>
        <DefaultNavbar
          routes={langContext =="Français" ? routes : routesEN}
          action={{
            type: "external",
            route: "https://www.creative-tim.com/product/material-kit-pro-react",
            label: "buy now",
            color: "info",
          }}
          brand={"Prevalung"}
            sticky
            transparent
            relative
            center
        />
      </MKBox>
      {isFetch ? (
      <>
      <MKBox
        ref={headerRef}
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687697009/Untitled_2_dl2hgr.png"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Clinical").fields.Titre}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
               {contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Clinical").fields.Texte}
            </MKTypography>

       {/*      <MKButton style={{marginTop:"5px"}} variant="gradient" color="primary" sx={{ width:"200px" }} onClick={()=>{executeScroll()}}>
            {langContext === "Français" ? "Rejoindre" : "Join"}
            </MKButton> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* FLEMENGHO */}
        <MKBox component="section" py={2} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <div style={{display:"flex",flexDirection:"column"}}>
                {/* <MKTypography
                  component="h6"
                  variant="button"
                  opacity={0.7}
                  textTransform="uppercase"
                  fontWeight="bold"
                >
                  {contentData.find(item => item.fields.key === "FLEMENGHO " + langContext && item.fields.Page === "Clinical").fields.Titre}
                </MKTypography> */}
                <MKTypography variant="h3" mb={3}>
                <span className="font-title-black-0">{contentData.find(item => item.fields.key === "CARE group " + langContext && item.fields.Page === "Clinical").fields.Titre}</span>
                </MKTypography>
              </div>
              <MKTypography variant="body2" style={{textAlign:"justify"}}>
                <span>
                  {contentData.find(item => item.fields.key === "CARE group " + langContext && item.fields.Page === "Clinical").fields.Texte}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        {/* ROBINSCA */}
        <MKBox component="section" py={0} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <div style={{display:"flex",flexDirection:"column"}}>
                {/* <MKTypography
                  component="h6"
                  variant="button"
                  opacity={0.7}
                  textTransform="uppercase"
                  fontWeight="bold"
                >
                  {contentData.find(item => item.fields.key === "ROBINSCA " + langContext && item.fields.Page === "Clinical").fields.Titre}
                </MKTypography> */}
                {/* <MKTypography variant="h3" mb={3}>
                <span className="font-title-black-2">{contentData.find(item => item.fields.key === "ROBINSCA " + langContext && item.fields.Page === "Clinical").fields.Titre}</span>
                </MKTypography> */}
              </div>
              <MKTypography variant="body2" style={{textAlign:"justify"}}>
                <span>
                  {contentData.find(item => item.fields.key === "8 Pays " + langContext && item.fields.Page === "Clinical").fields.Texte}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        {/* SUMMIT and NCL TLHC */}
        <MKBox component="section" py={0} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <div style={{display:"flex",flexDirection:"column"}}>
                
              <div>
      <style>
        {`
          .list {
            list-style-type: none;
            display:flex;
            flex-direction:column;
            padding: 0;
            margin: 0;
          }
          .list-item {
            margin: 0px 0;
            padding: 10px;
            border-radius: 5px;
            display: inline-block;
            font-size: 15px;
          }
        `}
      </style>
      {langContext == 'Anglais' ?
      <ul className="list">
        {countriesEN.map((country, index) => (
          <li key={index} className="list-item">
            - {country}
          </li>
        ))}
      </ul>
      :
      <ul className="list">
      {countriesFR.map((country, index) => (
        <li key={index} className="list-item">
          - {country}
        </li>
      ))}
    </ul>}
    </div>
    <img src={contentData.find(item => item.fields.key === "Carte " + langContext && item.fields.Page === "Clinical").fields.Image[0].url} alt="logo" style={{width:"100%", marginTop:"20px",alignSelf:"center"}}/>

              </div>
              <MKTypography variant="body2" style={{textAlign:"justify",marginTop:50}}>
                <span>
                  {contentData.find(item => item.fields.key === "Association " + langContext && item.fields.Page === "Clinical").fields.Texte}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>

        {/* PREVALUNG* */}
        <MKBox component="section" py={2} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <div style={{display:"flex",flexDirection:"column"}}>
                {/* <MKTypography
                  component="h6"
                  variant="button"
                  opacity={0.7}
                  textTransform="uppercase"
                  fontWeight="bold"
                >
                  {contentData.find(item => item.fields.key === "ROBINSCA " + langContext && item.fields.Page === "Clinical").fields.Titre}
                </MKTypography> */}
                <MKTypography variant="h3" mb={3}>
                <span className="font-title-black-0">{contentData.find(item => item.fields.key === "Optimisation " + langContext && item.fields.Page === "Clinical").fields.Titre}</span>
                </MKTypography>
              </div>
              <MKTypography variant="body2" style={{textAlign:"justify"}}>
                <span>
                  {contentData.find(item => item.fields.key === "Optimisation " + langContext && item.fields.Page === "Clinical").fields.Texte}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>

        {/* RISP */}
        <MKBox component="section" py={2} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <div style={{display:"flex",flexDirection:"column"}}>
                {/* <MKTypography
                  component="h6"
                  variant="button"
                  opacity={0.7}
                  textTransform="uppercase"
                  fontWeight="bold"
                >
                  {contentData.find(item => item.fields.key === "ROBINSCA " + langContext && item.fields.Page === "Clinical").fields.Titre}
                </MKTypography> */}
                <MKTypography variant="h3" mb={3}>
                <span className="font-title-black-0">{contentData.find(item => item.fields.key === "Actuellement " + langContext && item.fields.Page === "Clinical").fields.Titre}</span>
                </MKTypography>
              </div>
              <MKTypography variant="body2" style={{textAlign:"justify"}}>
                <span>
                  {contentData.find(item => item.fields.key === "Actuellement " + langContext && item.fields.Page === "Clinical").fields.Texte}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <div ref={myRef}>
          <ContactUsOne/>
        </div>
        {/* <Steps />
        <OurEfforts />
        <Features />
        <Posts />
        <Support /> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      </>): null
      }
    </>
  );
}

export default SingleArticle;
